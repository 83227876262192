import React, { Component } from 'react';
import moment from 'moment';
import t from './i18n';
import { pweb , pwebs, Loader , Utils, Route, Link,
    FormInputText, FormButtons, FormInputContainer , BoxContainer, SweetAlert, 
    ScrollBasedList } from './Common.js';
import { LocalCache, Globals } from './Globals';
import { ProfileData } from './User';

import './PrivateArea.css';
import {PrintModal} from "./OrderCreation";

class OrderShippingAddress extends Component {
    render(){
        const udata = pwebs().getState().userData;
        const shipping = udata.shipping_addresses[this.props.cdn_dm];

        if( !shipping )
            return <div className="row">-</div>;
        
        return <div className="row">
            <div className="col-md-12">
                <h4>{shipping.DES_DM} <small>{udata.cda_ana}/{this.props.cdn_dm}</small></h4>
                <p>{shipping.DES_INDIR}</p>
                <p>{shipping.SIG_CAP} {shipping.DES_LOC} ({shipping.SIG_PROV})</p>
            </div>

            {shipping.DES_TEL ? ( <div className="col-md-6">
                 <p><i className="fa fa-phone"></i> {shipping.DES_TEL}</p>
            </div>  ) : <span></span> }

            {shipping.DES_TELEX ? ( <div className="col-md-6">
                 <p><i className="fa fa-phone"></i> {shipping.DES_TELEX}</p>
            </div> ) : <span></span> }

            {shipping.DES_EMAIL ? (  <div className="col-md-6">
                <p><i style={{marginLeft: '10px'}} className="fa fa-envelope"></i> {shipping.DES_EMAIL}</p>
            </div> ) : <span></span> }
            
        </div>;
    }
}

class OrderView extends Component {
    constructor(props){
        super(props);

        this.state = {
            order: false,
            ...pweb().store.getState(),
        };

        pwebs().subscribe( () => { this.setState( pweb().store.getState() )} );
    }

    highlightedRow(){
        const location = window.location.pathname.split('/');
        if( location.length < 5 ) return -1;
        return parseInt( location[4] );
    }

    componentDidMount(){
        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: t('Orders'), url:'/order/list'},
            {label: t('View order'), url:'#'},
        ]));

        const location = window.location.pathname.split('/');

        if( !location ) return;

        if( location.length < 4 ) return;

        const order_id = location[3];

        if( !order_id.length ) return;

        const web_quotation_match = order_id.match( /[A-Z]{2}_([0-9]{2})_W[A-Z]_([0-9]+)/i );

        pweb().fetch( 'order' , 'get' , order_id , data => {

            if( data.status ){

                if( data.data.flg_sync_ok != 1 && web_quotation_match ){
                    Route.pushLocation( `/orderc/edit/${web_quotation_match[1]}-${web_quotation_match[2]}` );
                    return;
                }

                this.setState({order: data.data});

                pwebs().dispatch( pweb().setHeaderBreadcrumbs([
                    {label: t('Orders'), url:'/order/list'},
                    {label: t('View order'), url:'#', title: t('Order #') + OrdersUtil.formatNum( data.data ) }
                ]));

            } else {
                if( web_quotation_match ){
                    Route.pushLocation( `/orderc/edit/${web_quotation_match[1]}-${web_quotation_match[2]}` );
                    return;
                }
            }

        } );
    }

    render(){

        if( !this.state.order || !this.state.userData )
            return <Loader />;

        const o = this.state.order;

        let rowIndex = 1;

        console.log( [ this.highlightedRow() , rowIndex ] );
        

        return <div className="OrderView">

            <div className="row" style={{marginBottom: '10px'}}>

                {(this.state.order.pendingactions || this.state.order.sync_requested) ? <React.Fragment>
                    <div className={"col-md-12"}>
                        <p className="text-warning" style={{backgroundColor: '#f8ac59',
                            color: 'white',
                            marginTop: '10px',
                            fontSize: '15pt',
                            padding: '20px',
                            borderRadius: '10px'}}>
                            Ordine in fase di sincronizzazione
                        </p>
                    </div>
                </React.Fragment> : null }

                <div className="col-md-12 OrderViewActions">
                
                    { OrdersUtil.hasTracking( this.state.order ) && (
                        <Link className="btn btn-default" target="_blank" data-toggle="tooltip" data-placement="left" title="Traccia spedizione"
                            href={`/order/tracking/${OrdersUtil.formatNum(this.state.order,true)}`}>

                            <i className="fa fa-truck"></i> {t('Track')}
                        </Link>
                    )}

                    { (!(this.state.order.pendingactions || this.state.order.sync_requested) && this.state.userData.flg_abil == 1 ) && <React.Fragment>

                        { ( this.state.order.blocco_ordine === 50 || this.state.order.blocco_ordine === 9 ) ? (
                            <button className="btn btn-success" onClick={se => { OrdersUtil.actionConfirmQuotation( this.state.order )}}>
                                <i className="fa fa-check"></i> {t('Confirm quotation')}</button>
                        ):(<span></span>)}

                        { ( this.state.order.blocco_ordine === 50  ) ? (
                            <button className="btn btn-warning" onClick={se => { OrdersUtil.actionVoid( this.state.order )}}>
                                <i className="fa fa-times"></i> {t('Void quotation')}</button>
                        ):(<span></span>)}

                        { OrdersUtil.canBlock( this.state.order ) && (
                            <a className="btn btn-default" onClick={se => { OrdersUtil.actionBlock( this.state.order )}}>
                                <i className="fa fa-pause"></i> {t('Block')}</a>
                        )}

                        { OrdersUtil.canUnblock( this.state.order ) && (
                            <a className="btn btn-default" onClick={se => { OrdersUtil.actionUnblock( this.state.order )}}>
                                <i className="fa fa-play"></i> {t('Unblock')}</a>
                        )}

                        { OrdersUtil.canVoid( this.state.order ) && (
                            <a className="btn btn-primary" onClick={se => { OrdersUtil.actionVoid( this.state.order )}}>
                                <i className="fa fa-times"></i> {t('ANNULLA')}</a>
                        )}

                        { OrdersUtil.canUnvoid( this.state.order ) && (
                            <a className="btn btn-default" onClick={se => { OrdersUtil.actionUnvoid( this.state.order )}}>
                                <i className="fa fa-life-ring"></i> {t('Ripristina')}</a>
                        )}
                    </React.Fragment>}

                    <PrintModal num_eser_cont={this.state.order.num_eser_cont}
                                prg_doc={this.state.order.prg_doc}
                                cda_serie_num={this.state.order.cda_serie_num}
                                cda_polo_num={this.state.order.cda_polo_num}
                                cda_tipo_doc_num={this.state.order.cda_tipo_doc_num}

                                visible={this.state.printModal}
                                onClose={se=>{this.setState({
                                    printModal: false,
                                }/*, this.saveQuotation*/ )}} />

                    <a onClick={se => {document.getElementById("printform").submit()}} target="_blank" className="btn btn-default" >
                        <i className="fa fa-print"></i> {t('Print order confirm')}</a>

                    <form id="printform" style={{display: 'none'}} action={`${pweb().apiUrl()}/order/print/${OrdersUtil.formatNum(this.state.order, true)}`}
                          target="_blank" method="POST">
                        <input type="hidden" name="access_token" value={pwebs().getState().authorizationKey} />
                    </form>

                    <button className="btn btn-default" onClick={ se => {
                        se.preventDefault();
                        this.setState({printModal: true});
                    }} >
                        <i className="fa fa-print"></i> {t('Stampa personalizzata')}
                    </button>

                    { OrdersUtil.getUniqueDdt( this.state.order ) && (

                        <div>
                            <a onClick={se => {document.getElementById("printDDTform").submit()}} target="_blank" className="btn btn-default" >
                                    <i className="fa fa-print"></i> {t('Print delivery note')}</a>

                            <form id="printDDTform" style={{display: 'none'}} action={`${pweb().apiUrl()}/order/print/${
                                    OrdersUtil.formatNum( OrdersUtil.getUniqueDdt( this.state.order ) , true)}`} 
                                target="_blank" method="POST">
                                <input type="hidden" name="access_token" value={pwebs().getState().authorizationKey} />
                            </form>
                        </div>

                    )}              

                </div>
            </div>

            <div className="row">
                <div className="col-md-6">

                    <BoxContainer title={t('Order informations')} className="OrderViewInfo" >

                        <dl className="dl-horizontal">

                            {this.state.order.blocco_ordine !== 50 && this.state.order.blocco_ordine > 0 && (
                                <dt style={{color: 'hsla(0, 75%, 50%, 1)'}}>{t('Order blocked')}: </dt> ) }

                            {this.state.order.blocco_ordine !== 50 && this.state.order.blocco_ordine > 0 && (
                                <dd style={{color: 'hsla(0, 75%, 50%, 1)'}}>
                                    {OrdersUtil.formatBlockReasonBadge( o )} {OrdersUtil.formatBlockReason( o )}
                                </dd> ) }

                            <dt>{t('Order state: ')}</dt>
                            <dd>{OrdersUtil.formatStateBadge( this.state.order )}</dd>

                            <dt>{t('Order Num: ')}</dt>
                            <dd>{OrdersUtil.formatNum( this.state.order )}</dd>

                            <dt>{t('Creation date: ')}</dt>
                            <dd>{ this.state.order.dat_doc ? moment(this.state.order.dat_doc).format('D MMMM YYYY') : '-'}</dd>

                            {this.state.order.num_doc_ricev && ( <dt>{t('Mod. Inserimento')} :</dt> )}
                            {this.state.order.num_doc_ricev && (
                                <dd style={{fontWeight: 'normal'}}>{this.state.order.num_doc_ricev}</dd>
                            )}

                            {/* <dt>{t('Yours reference: ')}</dt>
                            <dd>{this.state.order.des_riferimento}</dd> */}

                            <dt>{t('Shipping date')} :</dt>
                            <dd style={{fontWeight: 'bold'}}>{this.state.order.dat_cons_rich ? moment(this.state.order.dat_cons_rich).format('D MMMM YYYY') : '-'}</dd>
                        </dl>
                    </BoxContainer>

                </div>
                <div className="col-md-6">
                    <BoxContainer title={t('Shipping')} >
                        {this.state.order.cdn_dm ? <OrderShippingAddress cdn_dm={this.state.order.cdn_dm} order={this.state.order} /> : <ProfileData /> }
                    </BoxContainer>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">

                    <BoxContainer style={{marginTop: '10px'}}>
                        <div className="table-responsive">
                            <table className="table table-striped">
                            
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>{t('Article')}</th>
                                        <th>{t('Qty')}</th>
                                        <th>{t('Width')}</th>
                                        <th>{t('Height')}</th>
                                        <th>{t('MQ')}</th>
                                        <th className="text-right">{t('Subtotal')}</th>
                                        <th className="text-right">{t('Discount')}</th>
                                        <th className="text-right">{t('Price')}</th>
                                        <th>{t('Subclient')}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.order.rows.map( row => { return (
                                        <tr id={`OrderViewRow${rowIndex}`} style={ rowIndex++ === this.highlightedRow() ? {backgroundColor: 'hsla(54, 112%, 93%, 1)', height: '5em'} : {height: '5em'}}>
                                            <td style={{textAlign: 'left'}} >
                                                <h4>{row.cda_art_alias} 

                                                {row.num_stato_riga === 0 && ['MAG','MAGOMA','VAR'].indexOf( row.cda_art ) === -1 && ( <small class="label label-default"><i class="fa fa-hourglass-half"></i> <span class="hidden-xs">{t('Waiting')}</span></small> )}
                                                {row.num_stato_riga === 50 && ( <small class="label label-warning"><i class="fa fa-cogs"></i> <span class="hidden-xs">{t('In lavorazione')}</span></small> )}
                                                {row.num_stato_riga === 99 && ( <small class="label label-primary"><i class="fa fa-check"></i> <span class="hidden-xs">{t('Prodotto')}</span></small> )}
                                                </h4>

                                                <div className="small text-muted" style={{position:'absolute', width:'85%', paddingTop: '6px'}}>
                                                    {row.dev_articolo} 
                                                    {row.dev_riga_doc ? 
                                                        <span style={{fontStyle: 'italic', marginLeft: '20px'}}> 
                                                            <i className="fa fa-pencil-alt"></i>
                                                            {row.dev_riga_doc} </span> : null}
                                                </div>
                                            </td>
                                            <td><h4>{row.qta_pezzi}</h4></td>
                                            <td>{row.val_base}</td>
                                            <td>{row.val_altezza}</td>
                                            <td>{row.qta_unimis_doc.toFixed(2)}</td>
                                            <td className="text-right">{Utils.formatCurrency(row.val_movim_netto)}</td>
                                            <td className="text-right">{Utils.formatPercent(row.val_coeff_scon)}</td>
                                            <td className="text-right">{Utils.formatCurrency(row.val_prz_total)}</td>
                                            <td>{row.des_subcliente}</td>
                                        </tr>
                                    )} )}

                                    <tr>
                                        <td></td>
                                        <td>
                                            <h3>{this.state.order.sum_qta_pezzi}</h3></td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">
                                            {Utils.formatCurrency(this.state.order.sum_val_movim_netto)}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">
                                            <h3>{Utils.formatCurrency(this.state.order.sum_val_prz_total)}</h3></td>
                                        <td></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </BoxContainer>

                    <BoxContainer className="hidden-md hidden-lg" style={{display: 'none'}}>

                        <div className="OrderViewHeader hidden-xs hidden-sm">

                            <div className="row">
                                <div className="col-md-5 text-left">{t('Article')}</div>
                                <div className="col-md-2 text-center">{t('Qty')}</div>
                                <div className="col-md-2 text-center">{t('Measures')}</div>
                                <div className="col-md-1 text-right">{t('Subtotal')}</div>
                                <div className="col-md-1 text-right">{t('Price')}</div>
                                <div className="col-md-1 text-center">{t('Subclient')}</div>
                            </div>

                            <hr />
                        </div>

                        {this.state.order.rows.map( row => { return (

                            <div key={row.id}>

                                {/* MOBILE */}
                                <div className="OrderViewRow feed-element hidden-md hidden-lg" style={{paddingBottom: 0}}>

                                    <div className="col-xs-12" style={{marginBottom: '15px'}}>
                                        <h4><span className="label label-success">{row.qta_pezzi}x</span> {row.cda_art_alias}</h4>
                                        <div className="small text-muted">{row.dev_articolo}</div>
                                    </div>

                                    <div className="col-xs-6 text-left">
                                        <p>&nbsp;</p>
                                        <p>
                                            <span className="block">{row.val_base}mm x {row.val_altezza}mm </span>
                                        </p>
                                    </div>

                                    <div className="col-xs-6 text-right">
                                        <p>{t('Subtotal: ')}{Utils.formatCurrency(row.val_movim_netto)}</p>
                                        <h4>{t('Total: ')}{Utils.formatCurrency(row.sum_val_prz_total)}</h4>
                                    </div>
                                </div>

                                {/* DESKTOP 
                                <div className="OrderViewRow feed-element hidden-xs hidden-sm">
                                    <div className="col-md-5 text-left">
                                        <h4>{row.cda_art_alias}</h4>
                                        <div className="small text-muted">{row.dev_articolo}</div>
                                    </div>
                                    
                                    <div className="col-md-2 text-center">
                                        <h4 className="text-center">{row.qta_pezzi}</h4>
                                    </div>
                                    <div className="col-md-2 text-center">
                                        <span className="text-center block">{row.val_base}x{row.val_altezza}</span>
                                    </div>
                                    <div className="col-md-1 text-right">
                                        <p>{Utils.formatCurrency(row.val_movim_netto)}</p>
                                    </div>
                                    <div className="col-md-1 text-right">
                                        <p>{Utils.formatCurrency(row.val_prz_total)}</p>
                                    </div>
                                    <div className="col-md-1 text-center">
                                        <h4 className="text-center">{row.des_subcliente}</h4>
                                    </div>
                                </div>*/}

                                <hr style={{margin: 0}} />

                            </div>
                        )})}

                        <div>

                            <div className="row hidden-md hidden-lg">
                                <div className="col-xs-6 text-left">
                                    <h3>{t('Qty: ')}{this.state.order.sum_qta_pezzi}</h3>
                                </div>
                                <div className="col-xs-6 text-right">
                                    <h3>{t('Total: ')}{Utils.formatCurrency(this.state.order.sum_val_movim_netto)}</h3>
                                </div>
                            </div>

                            {/* DESKTOP 
                            <div className="row hidden-sm hidden-xs">
                                <div className="col-md-2 col-md-offset-5 text-center">
                                    <h3>{this.state.order.sum_qta_pezzi}</h3>
                                </div>
                                <div className="col-md-offset-2 col-md-2 text-right">
                                    <h3>{Utils.formatCurrency(this.state.order.sum_val_movim_netto)}</h3>
                                </div>
                            </div> */ }
                        </div>
                    </BoxContainer>
                </div>
            </div>

        </div>;
    }
}

const OrdersUtil = {

    canBlock: function( order ){ 
        return order.cda_stato_doc === 'INS' && order.blocco_ordine === 0;
    },

    canVoid: function( order ){ 
        return order.cda_stato_doc === 'INS' && order.blocco_ordine === 0;
    },

    canUnvoid: function( order ){ 
        return order.cda_stato_doc === 'ANN' && order.blocco_ordine === 0;
    },

    canUnblock: function( order ){ 
        return order.blocco_ordine === 30;
    },

    getUniqueDdt( order ){
        let r = false;
        order.children.map( document => {
            if( document.cda_tipo_doc_num === 'BV' && document.cda_serie_num === 'B' ) 
                r = document;
        });

        return r;
    },

    actionVoid: function( order , cb ){

        SweetAlert.show( t('Conferma annullamento') , t(`Sei sicuro voler annullare l\'ordine #% ?` , OrdersUtil.formatNum( order ) ) , [
            { caption: t('Yes') , onClick: se=>{ 

                pweb().fetch( 'order' , 'void', OrdersUtil.formatNum( order , true ) , (msg) => {
                    if( cb ) cb( !msg.status );
                    LocalCache.groupClear( 'fetch' );
                    window.location.reload(); // Pls do not hurt me
                });

                SweetAlert.close() } },
            { caption: t('Cancel') , onClick: se=>{SweetAlert.close()} },
        ]);
    },

    actionUnvoid: function( order , cb ){

        pweb().fetch( 'order' , 'unvoid', OrdersUtil.formatNum( order , true ) , (msg) => {
            if( cb ) cb( !msg.status );
            LocalCache.groupClear( 'fetch' );
            window.location.reload(); // Pls do not hurt me
        });

    },

    actionBlock: function( order , cb ){

        const msg = order.blocco_ordine == 50 ? 
            t(`Sei sicuro di voler annullare il preventivo #% ?` , OrdersUtil.formatNum(order) ) :
            t(`This action will block the order #%. Are you sure?` , OrdersUtil.formatNum(order) );

        SweetAlert.show( t('Order confirm') , msg , [
            { caption: t('Yes') , onClick: se=>{ 

                pweb().fetch( 'order' , 'block', OrdersUtil.formatNum( order , true ) , (msg) => {
                    if( cb ) cb( !msg.status );
                    LocalCache.groupClear( 'fetch' );
                    window.location.reload(); // Pls do not hurt me
                });

                SweetAlert.close() } },
            { caption: t('Cancel') , onClick: se=>{SweetAlert.close()} },
        ]);
    },

    actionUnblock: function( order , cb ){
        pweb().fetch( 'order' , 'unblock', OrdersUtil.formatNum( order , true ) , (msg) => {
            if( cb ) cb( !msg.status );
            LocalCache.groupClear( 'fetch' );
            window.location.reload(); // Pls do not hurt me
        });
    },

    actionConfirmQuotation: function( order , cb ){

        const msg = order.blocco_ordine == 50 ? 
            t(`Sei sicuro di voler confermare il preventivo #% ?` , OrdersUtil.formatNum(order) ) :
            t(`This action will confirm the order #%. Are you sure?` , OrdersUtil.formatNum(order) );

        SweetAlert.show( t('Order confirm') , msg , [
            { caption: t('Yes') , onClick: se=>{ 

                pweb().fetch( 'order' , 'confirmQuotation', OrdersUtil.formatNum( order , true ) , (msg) => {
                    if( cb ) cb( !msg.status );
                    LocalCache.groupClear( 'fetch' );
                    window.location.reload(); // Pls do not hurt me
                });

                SweetAlert.close() } },
            { caption: t('Cancel') , onClick: se=>{SweetAlert.close()} },
        ]);

        
    },

    actionVoidQuotation: function( order, cb ){
        SweetAlert.show( t('Order void') , t(`This action will void the order #%. Are you sure?` , OrdersUtil.formatNum(order) ) , [
            { caption: t('Void order') , onClick: se=>{ 

                pweb().fetch( 'order' , 'voidQuotation', OrdersUtil.formatNum( order , true ) , (msg) => {
                    if( cb ) cb( !msg.status );
                    LocalCache.groupClear( 'fetch' );
                    window.location.reload(); // Pls do not hurt me
                });

                SweetAlert.close() } },
            { caption: t('Cancel') , onClick: se=>{SweetAlert.close()} },
        ]);
    },

    hasTracking: function( order ){
        if( order.cda_tipo_doc_num == 'BV' )
            return order.cda_modtrasp == 3;
        return order.cda_modtrasp == 3 && order.cda_stato_doc == 'EVA';
    },

    formatDocumentNum( order  , urlFriendly = false ){
        const ret = `${order.cda_tipo_doc_num}-${moment(order.dat_doc).format('YY')}-${order.cda_serie_num}/${['BV','FT'].indexOf( order.cda_tipo_doc_num ) != -1 ? order.num_fattura : order.prg_doc}`;

        if( urlFriendly )
            return OrdersUtil.formatNum( order , urlFriendly );
        return ret;
    },

    formatNum: function( order , urlFriendly = false ){
        const ret = `${order.cda_tipo_doc_num}-${moment(order.dat_doc).format('YY')}-${order.cda_serie_num}/${order.prg_doc}`;

        if( urlFriendly )
            return ret.replace( /[^0-9A-Z]+/ig , '_' );
        return ret;
    },

    formatState: function( order ){

        if( order.flg_attesa_sync == "1" && order.flg_sync_ok == "2" ){
            return t('Elaborazione in corso');
        }

        if( order.cda_stato_doc === 'ANN' ) return t('Annullato');
        if( order.cda_stato_doc === 'EVA' || order.cda_stato_doc === 'TRA' ) return t('Evaso');
        if( order.cda_stato_doc === 'INS' || order.cda_stato_doc === 'NEW' ) return t('Inserito');
        if( order.cda_stato_doc === 'LAV' ) return t('In lavorazione');
        if( order.cda_stato_doc === 'PRO' ) return t('Prodotto');
        return '---';
    },

    formatRiferimenti: function( orderOrString , truncate = true ){
        
        let stringValue = typeof( orderOrString ) === 'string' ? orderOrString : orderOrString.des_riferimento;

        let references = stringValue.split(',');
        let occurence = {};

        let unsorted_referer = references.map( value => {
            if( typeof( value ) !== 'string' )
                return '';
            return value.trim();
        }).filter( value => {                        
            return value.length > 0;
        }).map( v => {
            if( typeof( occurence[v] ) !== 'undefined' )
                occurence[v]++;
            else
                occurence[v] = 1;
        });

        occurence = Object.keys(occurence).sort((a, b)=>{
            return occurence[a] > occurence[b];
        }).join(', ');
        
        let v = occurence.length > 25 && truncate ? occurence.substr(0,23) + '...' : occurence;

        v = v.trim();

        if( v === '.' )
            return '';

        return v;
    },

    formatBlockReason( order ){
        if( order.blocco_ordine <= 0 || order.blocco_ordine === 50 ) 
            return false;
        
        switch( order.blocco_ordine ){
            case 25	: return t('attesa controllo nota');
            case 35	: return t('controllo destinazione merce');
            case 20	: return t('m.exp.>>verifica');
            case 4	: return t('variazione colore cliente');
            case 5	: return t('materiale indisponibile');
            case 6	: return t('profili in lav. c/terzi');
            case 0	: return t('nessuno');
            case 1	: return t('misure indicative');
            case 2	: return t('rientro materiale danneggiato');
            case 3	: return t('errore invio materiale');
            case 10	: return t('amministrativo');
            case 30	: return t('annullato temp. dal cliente');
            case 15	: return t('lav. interna PRIMED');
            case 7	: return t('temporaneamente sospeso');
            case 8	: return t('attesa comun. telefonica');
            case 9	: return t('attesa di conferma ordine');
            case 12	: return t('attesa pagamento anticipato');
            case 50	: return t('PREVENTIVO');
            case 51	: return t('In attesa di preventivo');
            case 60	: return t('produzione');
            default: return '';
        }
    },

    formatBlockReasonBadge( order ){
        if( order.blocco_ordine === 50 || !order.blocco_ordine ) return '';

        return <small className="fa-anim-blink label label-danger" style={{marginRight: '5px'}}>
            <i className="fa fa-exclamation"></i></small>;  
    },

    formatStateBadge: function( order ){
        const label = OrdersUtil.formatState( order );

        if( order.flg_attesa_sync == "1" && order.flg_sync_ok != 1 ){
            return <small className="label label-success"><i className="fa fa-check"></i> <span className="hidden-xs">Inviato</span></small>;
        }

        switch( order.cda_stato_doc ){
            case 'LAV':
            return <small className="label label-warning"><i className="fa fa-cogs"></i> <span className="hidden-xs">{label}</span></small>;
            case 'INS':
            return <small className="label label-success"><i className="fa fa-check"></i> <span className="hidden-xs">{label}</span></small>;
            case 'PRO':
            return <small className="label label-warning"><i className="fa fa-cube"></i> <span className="hidden-xs">{label}</span></small>;
            case 'EVA':
            return <small className="label label-primary"><i className="fa fa-check"></i> <span className="hidden-xs">{label}</span></small>;
            case 'ANN':
            return <small className="label label-danger"><i className="fa fa-times"></i> <span className="hidden-xs">{label}</span></small>;
            default:
            return <small className="label label-danger"><i className="fa fa-times"></i> </small>
        }
    },

    formatActions: function( order ){
        if( OrdersUtil.hasTracking( order ) ){
            return <span>
                <Link className="btn btn-xs btn-default" target="_blank"
                    href={`/order/tracking/${OrdersUtil.formatNum(order,true)}`}>{t('Track')}
                </Link>
            </span>
        }

        if( order.blocco_ordine === 50 ){
            return (<a className="btn btn-xs btn-default" onClick={se=>{
                OrdersUtil.actionConfirmQuotation(order);
            }}>{t('Conferma')}</a>);
        }

        return '';
    }
}

class OrdersTableRowReferer extends Component {
    constructor(props){
        super(props);
    }

    render(){

        let v = '-';

        try {

            v = [];

            v = this.props.order.subclienti.map( i => {
                return i.DES_SUBCLIENTE;
            }).join( ', ' );

            if( v.length < 2 ){
                v = this.props.order.rows.filter( row => {
                    return typeof ( row.DES_SUBCLIENTE ) == 'string';
                }).map( row => {
                    return row.DES_SUBCLIENTE;
                }).join( ', ');
            }

            v = OrdersUtil.formatRiferimenti( v );
            
        } catch ( e ) {
            v = '-';
        }
        
        return <td onClick={this.props.onClick} className="" >
            {v}
        </td>;
    }
}

class OrdersTableRow extends Component {
    render(){
        const props = {
            onRowClick: order =>{
                Route.pushLocation(`/order/view/${OrdersUtil.formatNum( order , true )}`)
            },
            ...this.props
        };

        const order = this.props.order;

        return <tr>
            {props.columns.map( column => {
                return column( order , false , se => { props.onRowClick( order ) } );
            })}
        </tr>;
    }
}

class OrdersTable extends Component {

    static columnOrderNumber(order, isLabel, onClick){
        if( isLabel )
            return <th>{t('# Documento')}</th>;
        return <td onClick={onClick} >{OrdersUtil.formatNum( order )}</td>;
    }

    static columnDocumentNumber(order, isLabel, onClick){
        if( isLabel )
            return <th>{t('# Documento')}</th>;
        return <td onClick={onClick} >{OrdersUtil.formatDocumentNum( order )}</td>;
    }

    static columnReferer(order, isLabel, onClick){
        return isLabel ? 
            <th className="" width="40%">{t('Vs. Rif')}</th> : <OrdersTableRowReferer order={order} onClick={onClick} />;
    }

    static columnQty(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-xs">{t('Qta')}</th> : 
            <td onClick={onClick}  className="hidden-xs">{order.sum_qta_pezzi}</td>;
    }

    static columnCreationDate(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs visible-xl">{t('Creation date')}</th> : 
            <td onClick={onClick}  className="hidden-md hidden-sm hidden-xs visible-xl">{moment(order.dat_doc).format('D MMM YY')}</td>;
    }

    static columnShipDate(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs">{t('Shipping date')}</th> : 
            <td onClick={onClick}  className="hidden-md hidden-sm hidden-xs">{order.dat_cons_rich ? moment(order.dat_cons_rich).format('D MMM YY') : '-'}</td>;
    }

    static columnNetTotalRow(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-xs">{t('Subtotal')}</th> : 
            <td onClick={onClick} >{Utils.formatCurrency( order.sum_val_movim_netto )}</td>;
    }

    static columnBadge(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs" width="16%"></th> : 
            <td onClick={onClick}  className="text-left">
                {OrdersUtil.formatBlockReasonBadge( order )}
                {OrdersUtil.formatStateBadge( order )}
            </td>;
    }

    static columnActions(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs" width="16%"></th> : 
            <td className="text-left">
                {OrdersUtil.formatActions( order )}
            </td>;
    }

    render(){
        const props = {
            columns: [ OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                OrdersTable.columnQty, OrdersTable.columnCreationDate, 
                OrdersTable.columnShipDate, OrdersTable.columnNetTotalRow,
                OrdersTable.columnBadge ],
            ...this.props
        };

        return <table className="table table-striped OrdersTable">
            <thead>
                <tr>{props.columns.map( column => {
                    return column( null , true );
                })}</tr>
            </thead>
            <tbody>
                {this.props.orders.map( order => {
                    return <OrdersTableRow key={OrdersUtil.formatNum( order )}
                        {...props} order={order} />;
                })}
            </tbody>
        </table>;
    }

}

class PageScrollBasedOrdersRowsList extends Component {
    constructor(props){
        super(props);

        this.requestItem = this.requestItem.bind(this);
    }

    requestItem( identifier , itemLoaded ){
        return <OrdersRowsList {...this.props} page={identifier} limit={20} onOrdersLoaded={rows=>{
            itemLoaded( rows.length < 20 );
        }} />;
    }

    requestNextItemIdentifier( previous ){
        if( typeof( previous ) !== 'number' ) return 0;
        return previous+1;
    }

    render(){
        return <ScrollBasedList requestItem={this.requestItem} 
            requestNextItemIdentifier={this.requestNextItemIdentifier} />;
    }
}

class PageScrollBasedOrderList extends Component {
    constructor(props){
        super(props);

        this.requestItem = this.requestItem.bind(this);
    }

    requestItem( identifier , itemLoaded ){
        return <OrdersList {...this.props} page={identifier} limit={20} onOrdersLoaded={rows=>{
            itemLoaded( rows.length < 20 );
        }} />;
    }

    requestNextItemIdentifier( previous ){
        if( typeof( previous ) !== 'number' ) return 0;
        return previous+1;
    }

    render(){
        return <ScrollBasedList requestItem={this.requestItem} 
            requestNextItemIdentifier={this.requestNextItemIdentifier} />;
    }
}

class OrdersSearch extends Component {
    constructor(props){
        super(props);

        this.state = {
            year: moment().year(),
            month: false
        };
    }

    componentWillMount(){

        this.updateState();

        this.onLocationChangeRemove = Route.onLocationChange( () => {
            //alert( 'loc change' );
            this.updateState();
        });
    }

    componentWillUnmount(){
        this.onLocationChangeRemove();
    }

    updateState(){
        const loc = window.location.pathname.split('/');
        const search_term = decodeURIComponent( loc[loc.length-1] );

        this.setState( {
            text_search: search_term
        } );

        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: t(`Ricerca per "${search_term}"`) , url:'#'},
        ]));
    }

    render(){

        let options = {
            quotations: -1,
            states: {nin: ['TRA']},
            text_search: this.state.text_search
        };

        if( this.state.year && this.state.month === "false" ){
            options.startDate = `01/01/${this.state.year}`;
            options.endDate = `31/12/${this.state.year}`;
        }

        if( this.state.year && !isNaN(parseInt(this.state.month)) ){
            options.startDate = moment().set('year',this.state.year).set('month',this.state.month).startOf('month').format('DD/MM/YYYY');
            options.endDate = moment().set('year',this.state.year).set('month',this.state.month).endOf('month').format('DD/MM/YYYY');
        }

        return <BoxContainer title={t('Search results')}>

            <YearMonthFilter year={this.state.year} month={this.state.month} 
                onChange={se=>{this.setState({year: se.year, month: se.month})}}/>

            <PageScrollBasedOrdersRowsList {...options} />
{/*
            <OrdersList quotations={-1} text_search={this.state.text_search} onOrdersLoaded={orders=>{
                if( orders.length === 1 ){
                    Route.pushLocation( `/order/view/${OrdersUtil.formatNum(orders[0], true)}` );
                }
            }} />  */}
        </BoxContainer>
    }
}

class OrdersListMonth extends Component {

    constructor(props){
        super(props);

        this.state = {
            orders: false,
            ajaxState: OrdersListMonth.AJAX_STATUS_WAITING
        };
    }

    static AJAX_STATUS_WAITING = 1;
    static AJAX_STATUS_LOADING = 2;
    static AJAX_STATUS_LOADED = 3;

    componentDidMount(){
        this.setState({ajaxState: OrdersListMonth.AJAX_STATUS_LOADING});

        const startDate = moment( this.props.startDate ).startOf('month').format('DD/MM/YYYY');
        const endDate = moment( this.props.startDate ).endOf('month').format('DD/MM/YYYY');

        const options = {
            ...this.props,
            page: 0,
            startDate: startDate,
            endDate: endDate,
            year: this.state.year,
            fetchStats: this.props.stats
        };

        pweb().fetch( 'order' , 'fetch' , options ,  data => {
            if( !data.status )
                return;

            this.setState({
                orders: data.data.rows,
                stats: data.data.stats,
                ajaxState: OrdersListMonth.AJAX_STATUS_LOADED,
            });

            if( this.props.onLoadComplete )
                this.props.onLoadComplete();
        } );

    }

    static columnTrack(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs" width="5%"></th> : 
            <td className="text-left">
                {OrdersUtil.hasTracking( order ) && <Link className="btn btn-xs btn-default" target="_blank"
                    data-toggle="tooltip" data-placement="left" title="Traccia spedizione"
                    href={`/order/tracking/${OrdersUtil.formatNum(order,true)}`}>
                    <i className="fa fa-truck"></i>
                </Link>}
            </td>;
    }

    render(){

        if( this.state.ajaxState === OrdersListMonth.AJAX_STATUS_LOADING ||
            this.state.ajaxState === OrdersListMonth.AJAX_STATUS_WAITING )
            return <Loader />;

        return <div className="">

            {this.state.stats ? (
                <p className="text-right">
                    {this.state.stats[0].row_count} righe trovate
                </p>
            ) : ''}
        
            <div className="row">
                <div className="col-sm-12">
                    <h2>{moment(this.props.startDate).format('MMMM YYYY')}</h2>
                </div>
            </div>

            {this.state.orders.length ? (

                
            <div className="row">
                <div className="col-sm-12" style={{padding: '20px'}}>
                    <OrdersTable orders={this.state.orders} columns={[ OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                            OrdersTable.columnQty, OrdersTable.columnCreationDate, 
                            OrdersTable.columnShipDate, OrdersTable.columnNetTotalRow,
                            OrdersTable.columnBadge , OrdersListMonth.columnTrack ]} />
                </div>
            </div>

            ) : (
                <p className="NoItemFound"  >{t('No elements founds.')}</p>
            )}
            
            
        </div>;

    }
}

class OrdersList extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            orders: []
        };
    }

    loadOrders( nextProps ){
        this.setState({loading: true, orders: []});

        const options = {
            page: 0,
            /*year: moment().year(),*/
            limit: 20,
            fetchTotals: true,
            ...nextProps
        };

        const fetchFunction = this.props.cache ? pweb().passiveCacheFetch.bind(pweb()) : pweb().fetch.bind( pweb() );

        fetchFunction( 'order' , 'fetch' , options ,  data => {
            if( !data.status )
                return;

            this.setState({
                orders: data.data.rows,
                loading: false,
            });

            if( this.props.onOrdersLoaded )
                this.props.onOrdersLoaded( data.data.rows );
        } );
    }

    componentWillReceiveProps( nextProps ){
        
        if( JSON.stringify( this.props ) === JSON.stringify( nextProps ) )
            return;

        this.loadOrders( nextProps );
    }

    componentDidMount(){
        this.loadOrders( this.props );
    }

    render(){

        return <div>
            {this.state.orders.length ? (

                <div className="row">
                    <div className="col-sm-12" style={{padding: '20px'}}>
                        <OrdersTable orders={this.state.orders} {...this.props} />
                    </div>
                </div>

            ) : this.state.loading ? (
                    <Loader />
                ):(
                    <p style={{textAlign: 'center', fontStyle: 'italic', padding: '20px'}} >{t('No elements founds.')}</p>
                )    
            }

        </div>;
    }
}


class OrdersRowsList extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            orders: []
        };
    }

    loadOrders( nextProps ){
        this.setState({loading: true, orders: []});

        const options = {
            page: 0,
            /*year: moment().year(),*/
            limit: 20,
            fetchTotals: true,
            ...nextProps
        };

        const fetchFunction = this.props.cache ? pweb().passiveCacheFetch.bind(pweb()) : pweb().fetch.bind( pweb() );

        fetchFunction( 'order' , 'fetch' , options ,  data => {
            if( !data.status )
                return;

            this.setState({
                orders: data.data.rows,
                loading: false,
            });

            if( this.props.onOrdersLoaded )
                this.props.onOrdersLoaded( data.data.rows );
        } );
    }

    componentWillReceiveProps( nextProps ){
        
        if( JSON.stringify( this.props ) === JSON.stringify( nextProps ) )
            return;

        this.loadOrders( nextProps );
    }

    componentDidMount(){
        this.loadOrders( this.props );
    }

    static columnReferer(order, isLabel, onClick){
        return isLabel ? 
            <th className="" width="40%">{t('Vs. Rif')}</th> : 
            <td onClick={onClick}  className="">{order.des_subcliente}</td>;
    }

    render(){

        return <div>
            {this.state.orders.length ? (

                <div className="row">
                    <div className="col-sm-12" style={{padding: '20px'}}>
                        <OrdersTable columns={[ OrdersTable.columnOrderNumber, OrdersRowsList.columnReferer,
                            OrdersTable.columnQty, OrdersTable.columnCreationDate, 
                            OrdersTable.columnShipDate, OrdersTable.columnNetTotalRow,
                            OrdersTable.columnBadge ]} orders={this.state.orders} {...this.props} />
                    </div>
                </div>

            ) : this.state.loading ? (
                    <Loader />
                ):(
                    <p style={{textAlign: 'center', fontStyle: 'italic', padding: '20px'}} >{t('No elements founds.')}</p>
                )    
            }

        </div>;
    }
}

class MonthsScrollBasedOrdersList extends Component {

    constructor(props){
        super(props);

        this.requestNextItemIdentifier = this.requestNextItemIdentifier.bind(this);
        this.requestItem = this.requestItem.bind(this);
    }

    componentWillReceiveProps(){
    }

    requestItem( identifier , itemReady = ()=>{} ){
        return <OrdersListMonth {...this.props} key={identifier} 
            /*stats={this.props.startDate == identifier}*/
            stats={false}
            startDate={identifier}
            onLoadComplete={itemReady} />
    }

    requestNextItemIdentifier( previous ){
        if( !previous )
            return moment( this.props.startDate ).format();

        return moment( previous ).startOf('month').subtract(2,'days').format();
    }

    render(){
        return <ScrollBasedList startDate={this.props.startDate} requestNextItemIdentifier={this.requestNextItemIdentifier}
            requestItem={this.requestItem} />;
    }
}

class YearMonthFilter extends Component {
    constructor(props){
        super(props);

        this.state = {
            year: props.year ? props.year : moment().year(),
            month: props.month,
        };
    }

    componentDidUpdate( prevProps , prevState ){
        if( typeof( this.props.onChange ) === 'function' && ( prevState.year != this.state.year || prevState.month !== this.state.month) )
            this.props.onChange( this.state );
    }

    render(){
        const years = []; for( let i = moment().year(); i >= 2000; i-- ) years.push( i );

        const months = []; for( let i = 0; i <= 11; i++ ) months.push( i );

        return <div className="row">
            <div className="form-group col-md-4 col-sm-4 OrdersListByMonthFilter">
                <label className="control-label col-md-5 col-xs-5" style={{width: 'auto'}}>{t('Year:')}</label>
                <div className="col-md-7 col-xs-7">
                    <select style={{width:'auto'}} className="form-control" onChange={se=>{this.setState({year:se.target.value,month:this.state.month})}} >
                        {years.map( year => {
                            return this.state.year === year ? <option selected value={year}>{year}</option> : 
                                <option value={year}>{year}</option>;
                        })}
                    </select>
                </div>
            </div>

            <div className="form-group col-md-4 col-sm-4 OrdersListByMonthFilter">
                <label className="control-label col-md-5 col-xs-5" style={{width: 'auto'}}>{t('Month:')}</label>
                <div className="col-md-7 col-xs-7">
                    <select style={{width:'auto'}} className="form-control" onChange={se=>{this.setState({year:this.state.year,month:se.target.value})}}>
                    <option value={false}>Tutti</option>
                        {months.map( month => {
                            const name = moment( month+1 , 'M' ).format('MMMM');
                            return this.state.month === month ? <option selected value={month}>{name}</option> : 
                                <option value={month}>{name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>;

    }
}

class OrdersListByMonth extends Component {

    constructor(props){
        super(props);

        this.state = {
            year: (new Date()).getFullYear(),
            month: moment().month(),
        };
    }

    applyFilter( year , month ){
        this.setState({
            year: year,
            month: month,
        });
    }

    render(){

        const years = []; for( let i = moment().year(); i >= 2000; i-- ) years.push( i );

        const months = []; for( let i = 0; i <= 11; i++ ) months.push( i );

        const startDate = moment().set({
            year: this.state.year,
            month: this.state.month,
            second: 59,
            minute: 59,
            hour: 23,
        }).add('1 day').format();

        return <BoxContainer title={this.props.title || t('Orders')} id="OrderList">

            <div className="">

                <form onSubmit={this.onSearchSubmit} className="form-horizontal">

                    <div className="row">
                        <div className="form-group col-md-4 col-sm-4 OrdersListByMonthFilter">
                            <label className="control-label col-md-5 col-xs-5" style={{width: 'auto'}}>{t('Year:')}</label>
                            <div className="col-md-7 col-xs-7">
                                <select style={{width:'auto'}} className="form-control" onChange={se=>{this.applyFilter(se.target.value,this.state.month)}} >
                                    {years.map( year => {
                                        return this.state.year === year ? <option selected value={year}>{year}</option> : 
                                            <option value={year}>{year}</option>;
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-md-4 col-sm-4 OrdersListByMonthFilter">
                            <label className="control-label col-md-5 col-xs-5" style={{width: 'auto'}}>{t('Month:')}</label>
                            <div className="col-md-7 col-xs-7">
                                <select style={{width:'auto'}} className="form-control" onChange={se=>{this.applyFilter(this.state.year,se.target.value)}}>
                                    {months.map( month => {
                                        const name = moment( month+1 , 'M' ).format('MMMM');
                                        return this.state.month === month ? <option selected value={month}>{name}</option> : 
                                            <option value={month}>{name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                </form>

                <MonthsScrollBasedOrdersList {...this.props} startDate={startDate} />

            </div>
        </BoxContainer>;

    }
}

class OrderTracking extends Component {
    constructor(props){
        super(props);

        this.STATE_LOADING = -1;
        this.STATE_ERROR = -2;

        this.state = { link: this.STATE_LOADING };
    }

    componentDidMount(){
        const url = window.location.pathname.split('/');

        pweb().fetch( 'order' , 'getTrackingUrl', url[url.length-1] , (msg) => {

            if( !/^http.+/i.exec( msg.data ) )
                return this.setState({
                    link: this.STATE_ERROR
                });

            this.setState({
                link: msg.data,
            });
        });
    }

    render(){
        if( this.state.link !== this.STATE_ERROR && this.state.link !== this.STATE_LOADING )
            window.location = this.state.link;

        if( this.state.link === this.STATE_ERROR )
            return <p style={{textAlign: 'center', fontSize: '18px', marginTop: '20px', fontWeight: 'bold'}}>
                Nessuna informazione di tracciabilità disponibile</p>;

        return <Loader />;
    }
}

export { OrderTracking, OrdersSearch, OrdersTable, OrdersList, OrdersListByMonth, 
    OrderView, OrdersUtil, OrdersListMonth, OrdersRowsList, OrderShippingAddress };